import { mapListings } from 'utils/mapping';

import { fetch, fetchWithToken } from 'utils/fetch';
import { urlEncodeBody } from 'utils/requests';
import keysToCamel from 'utils/keysToCamel';

export async function getListings(
  filters?: FeaturedFilters
): Promise<{ listings: Listing[]; filters: any }> {
  const params = {
    search: filters?.search,
    min_price: filters?.priceMin,
    max_price: filters?.priceMax,
    zip: filters?.zip,
    listing_type_hr: filters?.listingTypes?.join(','),
    with_images: filters?.withImages ? 1 : 0,
    not_closed: filters?.onlyOpen ? 1 : 0
  };
  const response = await fetchWithToken('/listings', { query: urlEncodeBody(params).toString() });
  const data = await response.json();

  return {
    listings: mapListings(data.listings),
    filters: keysToCamel(data.filters)
  };
}

export async function getFeaturedListings(): Promise<Listing[]> {
  const response = await fetch('/featured');

  const data = await response.json();

  return mapListings(data);
}

export async function getFeaturedListingsFilters(): Promise<{
  filters: FeaturedFilters;
  count: string;
  listings: Listing[];
  selectedListings: string[];
}> {
  const response = await fetchWithToken('/featured/admin');

  const data = await response.json();

  return {
    filters: {
      zip: data.filters.zip,
      listingTypes: data.filters.listing_type_hr
        ? data.filters.listing_type_hr.split(',').map((item: string) => item.trim())
        : [],
      priceMin: data.filters.min_price,
      priceMax: data.filters.max_price,
      withImages: data.filters.with_images
    },
    count: data.filtered_listings_count,
    listings: mapListings(data.selected),
    selectedListings: data.selected.map(item => item.id)
  };
}

export async function updateFeaturedListingsFilters(
  filters: FeaturedFilters
): Promise<{ count: string }> {
  const params = {
    with_images: filters?.withImages ? 1 : 0,
    search: filters?.search,
    min_price: filters?.priceMin,
    max_price: filters?.priceMax,
    zip: filters?.zip,
    listing_type_hr: filters?.listingTypes?.join(',')
  };

  const response = await fetchWithToken('/featured/filters', {
    method: 'PATCH',
    body: urlEncodeBody(params)
  });

  const data = await response.json();

  return {
    count: data.filtered_listings_count
  };
}

export async function clearFilters(): Promise<string> {
  const response = await fetchWithToken('/featured/filters/all', {
    method: 'DELETE'
  });

  const data = await response.json();

  return data.filtered_listings_count;
}

export async function addFeaturedListings(ids: string[]): Promise<Listing[]> {
  const body = {};
  ids.forEach((item, idx) => {
    body[`ids[${idx}]`] = item;
  });
  const response = await fetchWithToken('/featured', {
    method: 'POST',
    body: urlEncodeBody(body)
  });

  const data = await response.json();

  return mapListings(data.selected);
}

export async function deleteFeaturedListing(id: string): Promise<Listing[]> {
  const body = { 'ids[0]': id };

  const response = await fetchWithToken('/featured', {
    method: 'DELETE',
    body: urlEncodeBody(body)
  });

  const data = await response.json();

  return mapListings(data.list);
}
