import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import dayjs from 'dayjs';

import notify from 'notify';
import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import Image from 'components/Image';
import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import Button from 'components/Button';
import listingNoPhoto from 'assets/images/listing_no_photo.svg';
import { addFeaturedListings, getListings as getListingsRequest } from '../api';
import FeaturedContext from '../context';
import AddFeaturedFilters from './AddFeaturedFilters';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

const AddFeatured = ({ onAdded }) => {
  const [listings, setListings] = useState<Listing[]>([]);
  const { selectedListings, setSelectedListings } = useContext(FeaturedContext);
  const layout = useLayout();

  const handleListingItemCheckboxChange = id => {
    setSelectedListings(value =>
      value.includes(id) ? value.filter(item => item !== id) : [...value, id]
    );
  };

  const getListings = useCallback(async (filters?: any) => {
    try {
      const { listings } = await getListingsRequest({ ...filters, onlyOpen: true });
      setListings(listings);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Error: AddFeatured failed to fetch listings');
      notify(err.message);
    }
  }, []);

  const saveFeaturedListings = useCallback(async () => {
    try {
      const listings = await addFeaturedListings(selectedListings);
      notify('Successfully added featured listings');
      onAdded(listings);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Error: saveFeaturedListings failed to add listings');
      notify(err.message);
    }
  }, [onAdded, selectedListings]);

  useEffect(() => {
    getListings();
  }, [getListings]);

  const AddButton = useMemo(
    () => <StyledAddButton onClick={saveFeaturedListings}>Add</StyledAddButton>,
    [saveFeaturedListings]
  );

  let content;
  switch (layout) {
    case 'mobile':
      content = (
        <>
          {listings.map(
            ({ id, images, address1, currentPrice, offersCount, listingDate, url }, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="featured-table__item" key={`${id}_${idx}`}>
                <Checkbox
                  className="featured-table__checkbox"
                  checked={selectedListings.includes(id)}
                  onChange={() => handleListingItemCheckboxChange(id)}
                />
                <div className="featured-table-info">
                  <span className="featured-table-info__item featured-table-info__listing">
                    <Image
                      className="featured-table-info__listing-img"
                      cloudFront={images?.[0] || listingNoPhoto}
                      size="24x24"
                      alt="mini-feature"
                      error={listingNoPhoto}
                    />
                    {address1}
                  </span>
                  <span className="featured-table-info__item featured-table-info__price">
                    <span className="label">Price</span> {currencyFormatter.format(currentPrice)}{' '}
                  </span>
                  <span className="featured-table-info__item featured-table-info__offers">
                    <span className="label">Offers</span> {offersCount || '-'}
                  </span>
                  <span className="featured-table-info__item featured-table-info__date">
                    <span className="label">Listing date</span>{' '}
                    {dayjs(listingDate).format('MM/DD/YYYY')}
                  </span>
                  <span className="featured-table-info__item featured-table-info__link">
                    <span className="label">Link</span>
                    <a
                      href={url || undefined}
                      className="featured-link"
                      target="_blank"
                      rel="noreferrer"
                      title={address1}>
                      View page
                      <Icon name="arrow" />
                    </a>
                  </span>
                </div>
              </div>
            )
          )}
        </>
      );
      break;

    case 'tablet':
    case 'desktop':
      content = (
        <>
          <div className="featured-table-header featured-table__item">
            <div className="featured-table-info">
              <span className="featured-table-info__item featured-table-info__listing">
                Listing
              </span>
              <span className="featured-table-info__item featured-table-info__price">Price</span>
              <span className="featured-table-info__item featured-table-info__offers">Offers</span>
              <span className="featured-table-info__item featured-table-info__date">
                Listing date
              </span>
              <span className="featured-table-info__item featured-table-info__link">Link</span>
            </div>
          </div>
          {listings.map(
            ({ id, images, address1, currentPrice, offersCount, listingDate, url }, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="featured-table__item" key={`${id}_${idx}`}>
                <Checkbox
                  className="featured-table__checkbox"
                  checked={selectedListings.includes(id)}
                  onChange={() => handleListingItemCheckboxChange(id)}
                />
                <div className="featured-table-info">
                  <span className="featured-table-info__item featured-table-info__listing">
                    <Image
                      className="featured-table-info__listing-img"
                      cloudFront={images?.[0] || listingNoPhoto}
                      size="24x24"
                      alt="mini-feature"
                      error={listingNoPhoto}
                    />
                    {address1}
                  </span>
                  <span className="featured-table-info__item featured-table-info__price">
                    {currencyFormatter.format(currentPrice)}{' '}
                  </span>
                  <span className="featured-table-info__item featured-table-info__offers">
                    {offersCount || '-'}
                  </span>
                  <span className="featured-table-info__item featured-table-info__date">
                    {dayjs(listingDate).format('MM/DD/YYYY')}
                  </span>
                  <span className="featured-table-info__item featured-table-info__link">
                    <a
                      href={url || undefined}
                      className="featured-link"
                      target="_blank"
                      rel="noreferrer"
                      title={address1}>
                      View page
                      <Icon name="arrow" />
                    </a>
                  </span>
                </div>
              </div>
            )
          )}
        </>
      );
      break;
    default:
      return null;
  }

  return (
    <>
      <AddFeaturedFilters onSubmit={getListings} />
      <StyledAddFeatured className="featured-table scrollbar-custom">{content}</StyledAddFeatured>
      {AddButton}
    </>
  );
};

const StyledAddFeatured = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(
    100vh - 136px - 20px * 2
  ); /* view height minus header + footer height minus modal content paddings */
  overflow: auto;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    height: calc(100vh - 112px - (10px + 52px));
  }
  .featured-table {
    &__item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 16px 0;
      border-bottom: 1px solid ${props => props.theme.colors.seashell};
      &:last-of-type {
        border-bottom: none;
      }
    }
    &__checkbox {
      margin: 0 12px 0 0;
    }
    &__button {
      fill: ${props => props.theme.colors.graphite};
    }
  }
  .featured-table-info {
    display: flex;
    flex: 1;
    &__item {
      flex: 1;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      display: flex;
    }
    &__listing {
      flex: 2.5;
    }
    &__offers {
      flex: 0.75;
    }
    &__link {
      flex: 0.75;
    }
    &__listing-img {
      width: 24px;
      height: 24px;
      margin: 0 12px 0 0;
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    &__status-label {
      font-size: 12px;
      line-height: 16px;
      padding: 4px;
      border-radius: ${props => props.theme.misc.borderRadius};
      &.active {
        background: rgba(98, 177, 32, 0.22);
        color: ${props => props.theme.colors.green};
      }
      &.pending {
        background: ${props => props.theme.colors.bananaYellow};
        color: ${props => props.theme.colors.orange};
      }
      &.unknown {
        background: ${props => props.theme.colors.coldGray};
        color: ${props => props.theme.colors.darkColdGray};
      }
    }
  }
  .featured-table-header {
    .featured-table-info {
      &__item {
        color: ${props => props.theme.colors.grayDark};
        &:first-of-type {
          padding-left: 30px;
        }
      }
    }
  }
  .featured-link {
    color: ${props => props.theme.colors.red};
    fill: ${props => props.theme.colors.red};
    text-decoration: none;
    display: flex;
    align-items: center;
    .icon {
      margin-left: 5px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .featured-table-info {
      &__link {
        display: none;
      }
    }
    .featured-table-header {
      .featured-table-info {
        &__status {
          margin-right: 16px;
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .featured-table {
      &__item {
        display: block;
        clear: both;
      }
      &__checkbox {
        float: left;
      }
      &__button-box {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: white;
        padding: 10px;
      }
      &__button {
        position: absolute;
        top: 4px;
        right: 0;
      }
      &__checkbox {
        margin: 4px 12px 0 0;
      }
    }
    .featured-table-info {
      flex-direction: column;
      position: relative;
      &__link {
        display: block;
      }
      &__item {
        display: flex;
        flex: 1;
        padding: 0 0 16px;
        .label {
          width: 78px;
          margin: 0 38px 0 0;
          color: ${props => props.theme.colors.grayDark};
        }
        &:last-of-type {
          padding: 0;
        }
      }
    }
  }
`;

const StyledAddButton = styled(Button)`
  margin-top: 4px;
  margin-left: auto;
  width: 160px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    position: relative;
    top: 42px;
    height: 40px;
    min-width: 100%;
  }
`;

export default AddFeatured;
