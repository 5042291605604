import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Modal from 'components/Modal';
import Filters from '../Filters';

interface AddFeaturedFiltersProps {
  onSubmit: (value: FeaturedFilters) => void;
}

const AddFeaturedFilters: React.FC<AddFeaturedFiltersProps> = ({ onSubmit }) => {
  const methods = useForm();
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({});
  const layout = useLayout();
  const [openFiltersModal, setOpenFiltersModal] = useState(false);

  const [onSubmitDebounced] = useDebouncedCallback(onSubmit, 300);

  const handleSearchInputChange = e => {
    const { value } = e.target;
    setSearch(value);
    onSubmitDebounced({ ...filters, search: value });
  };

  const handleFiltersSubmit = filters => {
    setFilters(filters);
    onSubmit({ ...filters, search });
    setOpenFiltersModal(false);
  };

  switch (layout) {
    case 'mobile':
    case 'tablet':
      return (
        <StyledModalFilters>
          <StyledInput
            className="search-input"
            value={search}
            icon="search"
            iconPosition="left"
            placeholder="Search"
            onChange={handleSearchInputChange}
          />
          <div className="modal-filters">
            <Button
              secondary
              className="modal-filters__filter-btn"
              onClick={() => setOpenFiltersModal(true)}>
              <Icon name="filter" />
            </Button>
          </div>
          <StyledMobileFiltersModal
            className="add-new-modal"
            open={openFiltersModal}
            onClose={() => setOpenFiltersModal(false)}
            modalTitle="Filters">
            <FormProvider {...methods}>
              <Filters className="filters" onSubmit={handleFiltersSubmit} />
            </FormProvider>
          </StyledMobileFiltersModal>
        </StyledModalFilters>
      );

    case 'desktop':
      return (
        <StyledModalFilters>
          <StyledInput
            className="search-input"
            icon="search"
            iconPosition="left"
            placeholder="Search"
            value={search}
            onChange={handleSearchInputChange}
          />
          <FormProvider {...methods}>
            <Filters className="filters" onSubmit={handleFiltersSubmit} />
          </FormProvider>
        </StyledModalFilters>
      );
    default:
      return null;
  }
};

const StyledModalFilters = styled.div`
  display: flex;
  justify-content: space-between;
  .modal-filters {
    &__filter-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: -webkit-box;
    margin-bottom: 8px;
  }
`;

const StyledMobileFiltersModal = styled(Modal)`
  .filters {
    flex-direction: column;

    .dropdown-menu .dropdown-menu-content {
      min-width: 100%;
    }

    .dropdown,
    .dropdown-menu {
      width: 100%;
      max-width: unset;
      margin: 0 0 16px 0;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .modal-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
`;

const StyledInput = styled(Input)`
  width: 200px;
  margin: 0 32px 0 0;
  .input-component {
    height: 32px;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 240px;
  }
`;

export default AddFeaturedFilters;
