import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'components/PrivateRoute';
import FeaturedPage from 'views/FeaturedPage';
import Workspace from 'workspace';

const Featured = () => {
  return (
    <Workspace>
      <Router>
        <PrivateRoute path="/featured" component={FeaturedPage} admin />
      </Router>
    </Workspace>
  );
};

export default Featured;
