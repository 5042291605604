import React, { useState, useEffect, useCallback } from 'react';

import useLayout from 'hooks/useLayout';
import notify from 'notify';
import {
  deleteFeaturedListing as deleteFeaturedListingRequest,
  getFeaturedListingsFilters as getFeaturedListings,
  updateFeaturedListingsFilters
} from './api';

interface FeaturedContextValues {
  itemsCounter: number;
  listingItems: Listing[];
  autoSelectionFilters: FeaturedFilters | null;
  filteredListingsCount: string | null;
  setFilteredListingsCount: React.Dispatch<React.SetStateAction<string | null>>;
  selectedListings: string[];
  setListings: React.Dispatch<React.SetStateAction<Listing[]>>;
  setSelectedListings: React.Dispatch<React.SetStateAction<string[]>>;
  deleteFeaturedListing: (id: string) => Promise<void>;
  updateFilters: (value) => void;
}

const FeaturedContext = React.createContext({} as FeaturedContextValues);

export const FeaturedContextProvider = ({ children }) => {
  const [autoSelectionFilters, setAutoSelectionFilters] = useState<FeaturedFilters | null>(null);
  const amountPropMob = 4;
  const [listings, setListings] = useState<Listing[]>([]);
  const [selectedListings, setSelectedListings] = useState<string[]>([]);
  const [filteredListingsCount, setFilteredListingsCount] = useState<string | null>(null);
  const layout = useLayout();
  const listingItems = layout === 'desktop' ? listings : listings.slice(0, amountPropMob);
  const itemsCounter = listings?.length;

  const deleteFeaturedListing = useCallback(async id => {
    try {
      const listings = await deleteFeaturedListingRequest(id);
      const selectedListings = listings.map(({ id }) => id);
      setListings(listings);
      setSelectedListings(selectedListings);
    } catch (err) {
      notify(err.message);
    }
  }, []);

  const getListings = useCallback(async () => {
    try {
      const { filters, count, listings, selectedListings } = await getFeaturedListings();
      setListings(listings);
      setFilteredListingsCount(count);
      setAutoSelectionFilters(filters);
      setSelectedListings(selectedListings);
    } catch (err) {
      notify(err.message);
    }
  }, []);

  const updateFilters = useCallback(async value => {
    try {
      const { count } = await updateFeaturedListingsFilters(value);
      setFilteredListingsCount(count);
      notify('Filters applied');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Error: saveFilterChange');
      notify(err.message);
    }
  }, []);

  useEffect(() => {
    getListings();
  }, [getListings]);

  const values: FeaturedContextValues = {
    itemsCounter,
    listingItems,
    autoSelectionFilters,
    filteredListingsCount,
    setFilteredListingsCount,
    selectedListings,
    setListings,
    setSelectedListings,
    deleteFeaturedListing,
    updateFilters
  };
  return <FeaturedContext.Provider value={values}>{children}</FeaturedContext.Provider>;
};

export default FeaturedContext;
