import React from 'react';
import { useRifm } from 'rifm';

import { formatCurrency } from 'utils/rifmFormatters';
import Input, { InputProps } from 'components/Input';

interface InputCurrencyProps extends Omit<InputProps, 'onChange'> {
  onChange: (value: string) => void;
}

const InputCurrency: React.FC<InputCurrencyProps> = ({ value, onChange, ...props }) => {
  const rifm = useRifm({
    accept: /\d/g,
    value,
    onChange,
    format: formatCurrency
  });
  return <Input value={rifm.value} onChange={rifm.onChange} {...props} type="string" />;
};

export default InputCurrency;
